<template>
  <v-app class>
    <div>
      <v-alert v-model="alert"
               border="left"
               dark
               dismissible
               class="alert"
               :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
        {{alertMessage}}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-10 ml-auto mr-auto">
        <v-container>
          <div class="mb-8 mt-8 text-center">
            <h1>Summary Report</h1>
          </div>
          <div class="row">
            <div class="col-md-12 d-flex justify-start text-center">
              <div class="row">

                <div class="col-md-10">
                  <SelectField :items="contractEmployers"
                               v-model="formData.contractEmployerId"
                               :label="'Contractor Employer'"
                               :itemText="'name'"
                               :itemValue="'id'"
                               :returnObject="true"
                               @change="contractEmployerChange">
                  </SelectField>
                </div>

                <div class="col-md-2">
                  <Button :btnType="'Submit'" :isLoading="isSubmit" :label="'Submit'" @onClick="onSubmit" class="col-md-3" />
                </div>
              </div>
            </div>

          </div>
          <div class="col-md-8 d-flex justify-start">
            <div class="row">
              
              <div class="col-md-8 export-container">
                <SelectField :items="downloadFormat" v-model="selectedFormat" :label="'Download Format'" :itemText="'name'" :itemValue="'id'" :returnObject="true" @input="onSelectedFormat" />
                <Button :btnType="'Submit'" :isLoading="isDownloading" :label="'Export'" @onClick="onDownload" class="col-md-3 mr-2" />
              </div>
            </div>
          </div>
          <div v-if="loadingData" class="text-center">
            <v-progress-circular indeterminate
                                 color="primary"></v-progress-circular>
          </div>
          <v-data-table :headers='headers'
                        :items="summarycountrecords">
          </v-data-table>
          <table hidden id="summarytable">
            <thead>
              <tr>
                <th>Contractor Name</th>
                <th>Active</th>
                <th>Death</th>
                <th>Redeployed</th>
                <th>Redundancy</th>
                <th>Resigned</th>
                <th>Retired</th>
                <th>Grand Total</th>

              </tr>
            </thead>
            <tbody>
              <tr v-for="item in summarycountrecords" :key="item.ContractorName">
                <td>
                  {{item.contractorName}}
                </td>
                <td>
                  {{item.active}}
                </td>
                <td>
                  {{item.death}}
                </td>
                <td>
                  {{item.redeployed}}
                </td>
                <td>
                  {{item.redundancy}}
                </td>
                <td>
                  {{item.resigned}}
                </td>
                <td>
                  {{item.retired}}
                </td>
                <td>
                  {{item.grandTotal}}
                </td>
              </tr>
            </tbody>
          </table>
          <!-- confirm delete modal  -->
          <Dialog ref="deleteModal" :title="'Are you sure you want to delete'" :width="400">
            <template v-slot:footer>
              <Button :btnType="'Submit'" :label="'Yes'" @onClick="deleteJobtitle" class="mr-2" />
              <Button :btnType="'Cancel'" :label="'No'" @onClick="cancel('deleteModal')" />
            </template>
          </Dialog>
        </v-container>
      </div>
    </div>
  </v-app>
</template>
<script>
  import TextField from '@/components/ui/form/TextField.vue'
  import SelectField from '@/components/ui/form/SelectField.vue'
  import Button from '@/components/ui/form/Button.vue'
  import Dialog from '@/components/ui/Dialog.vue'
  import { requestService, salaryPeriodService, contractEmployerService, employeeService } from '@/services'
  import ExportToCSVFile from '@/utils/helper'
  import moment from 'moment'
  /*import XLSX from 'xlsx'*/
  import * as XLSX from 'xlsx/xlsx.mjs';
  import JSPDF from 'jspdf'
  import 'jspdf-autotable'
   export default {
    components: {
      SelectField,
      Button,
      Dialog,
      TextField
    },
    data() {
      return {
        contractEmployerId: 0,
        contractEmployers: [],
        isDownloading: false,
        isSubmit: false,
        loadingData: false,
        search: '',
        formData: {
          name: '',
          id: '',
          salaryPeriod: ''
        },
        payment: '',
        refPayment: '',
        valid: false,
        isSavingUser: false,
        alertMessage: '',
        alert: false,
        alertType: '',
        summarycountrecords: [],
        excelExportRecords: [],
        salaryPeriods: [],
        headers: [
          {
            text: 'Contractor Name',
            value: 'contractorName'
          },
          {
            text: 'Active',
            value: 'active'
          },
          {
            text: 'Death',
            value: 'death'
          },
          {
            text: 'Redelpoyed',
            value: 'redeployed'
          },
          {
            text: 'Redundancy',
            value: 'redundancy'
          },
          {
            text: 'Resigned',
            value: 'resigned'
          },
          {
            text: 'Retired',
            value: 'retired'
          },
          {
            text: 'Grand Total',
            value: 'grandTotal'
          }
        ],       
        downloadFormat: [
          {
            name: 'Excel',
            id: 'excel'
          },
          {
            name: 'PDF',
            id: 'pdf'
          },
          {
            name: 'CSV',
            id: 'csv'
          }
        ],
        selectedFormat: ''
      }
    },
    computed: {
     
    },
    methods: {
      addNew() {
        
      },
      showAlertMessage(message, type) {
        this.alertMessage = message
        this.alert = true
        this.alertType = type
        setTimeout(() => { this.alert = false }, 5000)
      },
      openDeleteModal(item) {
        this.$refs.deleteModal.toggleModal()
        this.formData.name = item.name
        this.formData.id = item.id
      },
      deleteJobtitle() {
       
      },
      cancel(modal) {
        if (modal === 'deleteModal') {
          this.$refs.deleteModal.toggleModal()
        }
      },
      onSelectedFormat(value) {
        this.selectedFormat = value.id
      },
      onSubmit() {
        this.isSubmit = true              

        employeeService.getSummaryCountReport(this.formData.contractEmployerId.id).then(result => {
          this.summarycountrecords = result.data.items
          this.excelExportRecords = result.data.items
          this.isLoading = false
          }).catch(error => {
          this.showAlertMessage(error.message, 'error')
        }).finally(() => {
          this.isSubmit = false
        })        
      },
      onDownload() {
        if (this.selectedFormat !== '') {
          try {
            this.isDownloading = true
            const time = moment().format()
            switch (this.selectedFormat) {
              case 'pdf':
                const doc = new JSPDF('landscape')
                doc.autoTable({
                  theme: 'striped',
                  headStyles: { fillColor: '#0B2D71', textColor: '#fff' },
                  alternateRowStyles: { fillColor: '#F3F4F8' },
                  html: '#summarytable'
                })

                doc.text('SummaryCountReport-CMMS', 150, 10, { align: 'center' })              
                doc.save(`SummaryCountReport_${time}.pdf`)
                this.isDownloading = false
                break


              case 'excel':
                const formattedexcelData = []
                const excelfileHeaders = {
                  ContractorName: 'Contractor Name',
                  Active: 'Active',
                  Death: 'Death',
                  Redelpoyed: 'Redelpoyed',
                  Redundancy: 'Redundancy',
                  Resigned: 'Resigned',
                  Retired: 'Retired',
                  GrandTotal: 'Grand Total'
                }

                this.excelExportRecords.forEach(item => {
                  formattedexcelData.push({
                    ContractorName: item.contractorName.replace(/,/g, ''),
                    Active: item.active,
                    Death: item.death,
                    Redelpoyed: item.redelpoyed,
                    Redundancy: item.redundancy,
                    Resigned: item.resigned,
                    Retired: item.retired,
                    GrandTotal: item.grandTotal
                  })
                })   
               
                var PersonnelList = XLSX.utils.json_to_sheet(formattedexcelData)
                var PersonnelListWorkBook = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(PersonnelListWorkBook, PersonnelList, 'SummaryReport')
                XLSX.writeFile(PersonnelListWorkBook, `SummaryReport_${time}.xlsx`)
                this.isDownloading = false
                break


              case 'csv':
                const formattedData = []
                const fileHeaders = {
                  ContractorName: 'Contractor Name',
                  Active: 'Active',
                  Death: 'Death',
                  Redelpoyed: 'Redelpoyed',
                  Redundancy: 'Redundancy',
                  Resigned: 'Resigned',
                  Retired: 'Retired',
                  GrandTotal: 'Grand Total'
                }              

                this.excelExportRecords.forEach(item => {
                  formattedData.push({
                    ContractorName: item.contractorName.replace(/,/g, ''),
                    Active: item.active,
                    Death: item.death,
                    Redelpoyed: item.redelpoyed,
                    Redundancy: item.redundancy,
                    Resigned: item.resigned,
                    Retired: item.retired,
                    GrandTotal: item.grandTotal
                  })
              })               

                ExportToCSVFile(fileHeaders, formattedData, `SummaryCountReport_${time}`)
                this.isDownloading = false
                break
              default:
                break
            }
          } catch (error) {
            this.isDownloading = false
          }
        }
      },
      formatAmount(value) {
        return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }     
    },
    mounted() {     

      contractEmployerService.getAllContractEmployers().then((result) => {
        this.contractEmployers = result.data.items
        if (result.data.items.length > 1) {
          this.contractEmployers.unshift({ 'id': 1, 'name': 'All' })
        }
      })
    }
  }
</script>
<style scoped>
  .export-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .search-btn {
    background-color: #e6e3e3;
    height: 40px;
    border-radius: 3px !important;
  }

  .row-pointer > > > tbody tr :hover {
    cursor: pointer;
  }

  .alert {
    position: fixed;
    z-index: 9999;
    top: 5px;
    min-width: 50%;
  }

  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
</style>
